import { registerLocaleData } from '@angular/common';
import localeKo from '@angular/common/locales/ko';
import { Component, Injector } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppInjector } from '@pixels/client/app-injector/app.injector';

@Component({
  standalone: true,
  selector: 'app-root',
  imports: [
    RouterOutlet
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'flynnlab';

  constructor(injector: Injector) {
    registerLocaleData(localeKo, 'ko');
    AppInjector.getInstance().setInjector(injector);
  }
}
